import { styled, withDisplayName } from '@/stitches.config';

const _Line = styled('hr', {
  all: 'unset',
  position: 'relative',
  display: 'block',
  us: 'none',
  pe: 'none',
  bc: '$primary',
  width: '$size-8',
  height: '$size-1',
  variants: {
    size: {
      '2': {
        my: '$space-2',
      },
      '4': {
        my: '$space-4',
      },
      '5': {
        my: '$space-5',
      },
    },
    variant: {
      white: {
        bc: '$white',
      },
    },
  },
  defaultVariants: {
    size: '4',
  },
});
export const Line = withDisplayName(_Line, 'Line') as typeof _Line;
