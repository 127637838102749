import React, { forwardRef, memo, ReactNode } from 'react';

import { CSS, styled } from '@/stitches.config';

const GridStyled = styled('div', {
  display: 'grid',
  variants: {
    templateRows: {
      '1': {
        gridTemplateRows: 'repeat(1, 1fr)',
      },
      '2': {
        gridTemplateRows: 'repeat(2, 1fr)',
      },
      '3': {
        gridTemplateRows: 'repeat(3, 1fr)',
      },
      '4': {
        gridTemplateRows: 'repeat(4, 1fr)',
      },
      '12': {
        gridTemplateRows: 'repeat(12, 1fr)',
      },
    },
    templateColumns: {
      '1': {
        gridTemplateColumns: 'repeat(1, 1fr)',
      },
      '2': {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
      '3': {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },
      '4': {
        gridTemplateColumns: 'repeat(4, 1fr)',
      },
      '12': {
        gridTemplateColumns: 'repeat(12, 1fr)',
      },
    },
    autoFlow: {
      column: {
        gridAutoFlow: 'column',
      },
      row: {
        gridAutoFlow: 'row',
      },
      dense: {
        gridAutoFlow: 'dense',
      },
      'row-dense': {
        gridAutoFlow: 'row dense',
      },
      'column-dense': {
        gridAutoFlow: 'column dense',
      },
    },
    gap: {
      '0': {
        gap: '$space-0',
      },
      '0-5': {
        gap: '$space-0-5',
      },
      '1': {
        gap: '$space-1',
      },
      '2': {
        gap: '$space-2',
      },
      '3': {
        gap: '$space-3',
      },
      '4': {
        gap: '$space-4',
      },
      '5': {
        gap: '$space-5',
      },
      '6': {
        gap: '$space-6',
      },
      '7': {
        gap: '$space-7',
      },
      '8': {
        gap: '$space-8',
      },
      '9': {
        gap: '$space-9',
      },
      '10': {
        gap: '$space-10',
      },
      '12': {
        gap: '$space-12',
      },
      '14': {
        gap: '$space-14',
      },
      '16': {
        gap: '$space-16',
      },
      '18': {
        gap: '$space-18',
      },
      '20': {
        gap: '$space-20',
      },
    },
    columnGap: {
      '0': {
        columnGap: '$space-0',
      },
      '0-5': {
        columnGap: '$space-0-5',
      },
      '1': {
        columnGap: '$space-1',
      },
      '2': {
        columnGap: '$space-2',
      },
      '3': {
        columnGap: '$space-3',
      },
      '4': {
        columnGap: '$space-4',
      },
      '5': {
        columnGap: '$space-5',
      },
      '6': {
        columnGap: '$space-6',
      },
      '7': {
        columnGap: '$space-7',
      },
      '8': {
        columnGap: '$space-8',
      },
      '9': {
        columnGap: '$space-9',
      },
      '10': {
        columnGap: '$space-10',
      },
      '12': {
        columnGap: '$space-12',
      },
      '14': {
        columnGap: '$space-14',
      },
      '16': {
        columnGap: '$space-16',
      },
      '18': {
        columnGap: '$space-18',
      },
      '20': {
        columnGap: '$space-20',
      },
    },
    rowGap: {
      '0': {
        rowGap: '$space-0',
      },
      '0-5': {
        rowGap: '$space-0-5',
      },
      '1': {
        rowGap: '$space-1',
      },
      '2': {
        rowGap: '$space-2',
      },
      '3': {
        rowGap: '$space-3',
      },
      '4': {
        rowGap: '$space-4',
      },
      '5': {
        rowGap: '$space-5',
      },
      '6': {
        rowGap: '$space-6',
      },
      '7': {
        rowGap: '$space-7',
      },
      '8': {
        rowGap: '$space-8',
      },
      '9': {
        rowGap: '$space-9',
      },
      '10': {
        rowGap: '$space-10',
      },
      '12': {
        rowGap: '$space-12',
      },
      '14': {
        rowGap: '$space-14',
      },
      '16': {
        rowGap: '$space-16',
      },
      '18': {
        rowGap: '$space-18',
      },
      '20': {
        rowGap: '$space-20',
      },
    },
    alignContent: {
      center: {
        alignContent: 'center',
      },
      start: {
        alignContent: 'start',
      },
      end: {
        alignContent: 'end',
      },
      around: {
        alignContent: 'space-around',
      },
      between: {
        alignContent: 'space-between',
      },
      evenly: {
        alignContent: 'space-evenly',
      },
      stretch: {
        alignContent: 'stretch',
      },
    },
    alignItems: {
      center: {
        alignItems: 'center',
      },
      start: {
        alignItems: 'start',
      },
      end: {
        alignItems: 'end',
      },
      baseline: {
        alignItems: 'baseline',
      },
      stretch: {
        alignItems: 'stretch',
      },
    },
    justifyContent: {
      center: {
        justifyContent: 'center',
      },
      start: {
        justifyContent: 'start',
      },
      end: {
        justifyContent: 'end',
      },
      around: {
        justifyContent: 'space-around',
      },
      between: {
        justifyContent: 'space-between',
      },
      evenly: {
        justifyContent: 'space-evenly',
      },
      stretch: {
        justifyContent: 'stretch',
      },
    },
    justifyItems: {
      center: {
        justifyItems: 'center',
      },
      start: {
        justifyItems: 'start',
      },
      end: {
        justifyItems: 'end',
      },
      baseline: {
        justifyItems: 'baseline',
      },
      stretch: {
        justifyItems: 'stretch',
      },
    },
  },
});

interface GridProps extends React.ComponentProps<typeof GridStyled> {
  /**
   * The children of the Grid component.
   */
  children: ReactNode;

  /**
   * Additional CSS styles to apply to the Grid component.
   */
  css?: CSS;

  /**
   * Additional CSS class name to apply to the Grid component.
   */
  className?: string;

  /**
   * The HTML element to render as the root element of the Grid component.
   * Defaults to 'div'.
   */
  as?: 'div' | 'ol' | 'span' | 'ul';

  /**
   * The ARIA role of the Grid component.
   */
  role?: string;

  /**
   * The ID attribute of the Grid component.
   */
  id?: string;
  test?: string;
}

/**
 * The Grid component is a layout component that renders its children in a grid layout.
 * It accepts various props to customize the grid layout.
 *
 * @param props - The props for the Grid component.
 * @param props.children - The content of the grid.
 * @param props.css - Additional CSS styles for the grid.
 * @param props.className - Additional CSS class name for the grid.
 * @param props.as - The HTML element to use for the grid. Default is 'div'.
 * @param props.id - The ID attribute for the grid.
 * @param props.role - The ARIA role for the grid.
 * @param props.gap - The CSS grid gap property.
 * @param props.rowGap - The CSS grid row gap property.
 * @param props.columnGap - The CSS grid column gap property.
 * @param props.justifyItems - The CSS grid justify items property.
 * @param props.alignItems - The CSS grid align items property.
 * @param props.justifyContent - The CSS grid justify content property.
 * @param props.alignContent - The CSS grid align content property.
 * @param ref - The ref for the grid element.
 * @returns The rendered Grid component.
 */
const GridComponent = forwardRef<HTMLDivElement, GridProps>(
  (
    {
      children,
      css,
      className,
      as = 'div',
      id,
      role,
      test,
      ...rest
    }: GridProps,
    ref
  ) => {
    return (
      <GridStyled
        as={as}
        id={id}
        role={role}
        css={css}
        className={className}
        ref={ref}
        data-test={test}
        {...rest}
      >
        {children}
      </GridStyled>
    );
  }
);

GridComponent.displayName = 'Grid';

export const Grid = memo(GridComponent);
